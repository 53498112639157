<template>
    <h-popover :id="product?.id"
        class="z-20">
        <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
        <div ref="triggerNode">
            <div class="flex size-8 items-center justify-center rounded-full border border-red-500">
                <h-popover-button class="size-6 rounded-full bg-red-500 text-white outline-none hover:bg-red-600
                    focus:bg-red-600 active:bg-red-700 lg:text-lg"
                    @click="updatePopper(0)"
                    @keyup="updatePopper(0)">
                    <v-icon icon="add" />
                </h-popover-button>
            </div>
        </div>

        <div ref="popperNode"
            class="z-30">
            <transition
                enter-active-class="transition duration-150"
                :enter-from-class="`opacity-0 ${placement === 'top' ? 'translate-y-2' : '-translate-y-2'}`"
                enter-to-class="opacity-100"
                leave-active-class="transition duration-150"
                leave-from-class="opacity-100"
                :leave-to-class="`opacity-0 ${placement === 'top' ? 'translate-y-2' : '-translate-y-2'}`"
            >
                <h-popover-panel class="z-30 w-64">
                    <nuxt-link :to="`/${$t('storyblok.product')}/` + product?.seo.slug"
                        :aria-label="product?.title"
                        class="block rounded bg-gray-50 p-sm text-xs shadow-2xl">
                        <p class="font-sans-caption font-bold leading-4 lg:text-base lg:leading-5">
                            {{ product?.title }}
                        </p>

                        <p class="text-gray-500 lg:text-base lg:leading-6">
                            {{ product?.specification }}
                        </p>

                        <div class="mt-xs flex items-end gap-x-xs">
                            <p v-if="product?.pricing.price?.amount"
                                class="font-prenton-cond text-lg leading-none text-red-500 lg:text-3xl lg:leading-7">
                                {{ product?.pricing.price?.amount }} €
                            </p>

                            <p v-if="product?.pricing.price?.amount !== product?.pricing.originalPrice?.amount"
                                class="text-xs text-gray-500 line-through lg:text-sm">
                                {{ product?.pricing.originalPrice?.amount }} €
                            </p>
                        </div>
                    </nuxt-link>
                </h-popover-panel>
            </transition>
        </div>
    </h-popover>
</template>

<script setup lang="ts">
import type { ProductImageLiftLink } from '~/types';
import type {
    MarkerSimpleProductDataFragment,
    ViewMarkerProductsQuery,
} from '~~/graphql';

const properties = defineProps<{
    marker: ProductImageLiftLink
    products: ViewMarkerProductsQuery['products']['edges'][0]['node'][]
}>();

const triggerNode = ref<HTMLDivElement>();
const popperNode = ref<HTMLDivElement>();

const { placement, updatePopper } = usePopper(
    triggerNode,
    popperNode,
    'auto',
);

const product = computed<MarkerSimpleProductDataFragment | undefined>(() => {
    const item = properties.products?.find((p) => p.id === properties.marker.product?.source?.id);

    if (item?.__typename === 'SimpleProduct') {
        return item;
    }

    if (item?.__typename === 'ConfigurableProduct') {
        return item.configuration.defaultProduct ?? undefined;
    }

    return undefined;
});
</script>
